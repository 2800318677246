import React, { Component } from 'react'
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import Service from 'src/Service';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import campaignFieldss from './campaign.json';
import LoadingComponent from 'src/LoadingComponent';
import { Specialization } from 'src/Utils/constants';
import { getFormFields, isFormValid, onChipsChange, onEmailChange, onNumberChange, onTextChange } from 'src/Utils/formHelper';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import Header from 'src/Header';
import './styles.scss'
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from 'src/ClassicEditor';
import { trimObj } from 'src/Utils';
import ServerlessService from 'src/Service/ServerlessService';
import { MultiSelect } from 'primereact/multiselect';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import DirectFileUpload from 'src/components/common/directUpload';
import { CLOUDFRONT_ENDPOINT } from 'src/Service/config';
let campaignFields = _.cloneDeep(campaignFieldss);


const IVR_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '*', '#'];

class CreateCampaign extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(campaignFields, this.props.campaign ? this.props.campaign : { isLiveReportNeeded: true, "runImmediate": true, });
        this.state = {
            campaign: {
                ...this.formFields.data, // campaignId: uuidv4(),
                campaignSource: 'backend', retryCount: "0"
            },
            formValidations: this.formFields.formValidations,
            ivrMappings: this.props.campaign ? this.props.campaign.ivrMappings : {
            },
            campaignSessionId: uuidv4()
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();

    }

    saveCampaign = () => {
        let campaignData = trimObj(this.state.campaign)
        const formStatus = isFormValid(campaignFields, this.formFields.formValidations, campaignData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
        } else {

            // if (Number(campaignData.leadsDataCount) < 0) {
            //     return this.toast.show({ severity: 'error', summary: 'Upload leads data', detail: 'Please upload leads data to run the campaign.', life: 3000 });
            // }

            // if (campaignData.airtelAudioURL == '') {
            //     return this.toast.show({ severity: 'error', summary: 'Upload audio file', detail: 'Please upload audio file to run the campaign.', life: 3000 });
            // }



            if (campaignData.campaignType == 'ivr') {
                if (!campaignData.ivrValues.length) {
                    this.setState({
                        ivrValuesRequired: true
                    })
                }
                let isValid = true;
                if (this.state.ivrMappings && Object.keys(this.state.ivrMappings).length) {
                    Object.keys(this.state.ivrMappings).forEach(key => {
                        if (!this.state.ivrMappings[key] || this.state.ivrMappings[key].trim() == '') {
                            isValid = false;
                        }
                    });
                } else {
                    isValid = false;
                }


                if (!isValid) {

                    return this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please enter all Ivr mapping fields required.', life: 3000 });
                }

            }


            if (campaignData.campaignSource == 'backend' && campaignData.isLiveReportNeeded != true) {
                return this.toast.show({ severity: 'error', summary: 'Live report should be enabled', detail: 'Please enable live report.', life: 3000 });
            }

            campaignData.ivrMappings = this.state.ivrMappings;
            campaignData.campaignSessionId = this.state.campaignSessionId;

            campaignData.file_audioKey = this.state.file_audioKey;
            campaignData.file_leadsKey = this.state.file_leadsKey;
            //  console.log(campaignData, 'payload')

            //  alert('done')
            // return

            let url = `/voice-bulk/create`
            if (this.props.campaign) {
                url = `/voice-bulk/update`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, campaignData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.campaign ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }

    updateAudioFormFields = (isRequired) => {


        campaignFields = campaignFields.map(field => {
            // sendshortly
            if (field.FieldName == 'audioKey' || field.FieldName == 'leadsKey' || field.FieldName == 'retryCount') {
                field.Required = isRequired;
            }
            if (field.FieldName == 'campaignId') {
                field.Required = !isRequired;
            }

            return field;
        });

        this.formFields = getFormFields(campaignFields, this.state.campaign);


        this.setState({
            formValidations: this.formFields.formValidations
        })
    }


    updateFormFields = (isRequired) => {
        campaignFields = campaignFields.map(field => {
            if (field.FieldName == 'ivrValues') {
                field.Required = isRequired;
            }
            return field;
        });

        this.formFields = getFormFields(campaignFields, this.state.campaign);


        this.setState({
            formValidations: this.formFields.formValidations
        })
    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.campaign ? 'Update' : 'Save'} Campaign`} className='sp-m-lr10'
                onClick={this.saveCampaign}
            />
        </div>)
    }

    onChangeField = (key, value) => {
        this.setState((prevState) => {

            return {
                ivrMappings: {
                    ...prevState.ivrMappings,
                    [key]: value
                }
            };
        });

    }


    onIVRValueChange = () => {
        const { campaign, formValidations, ivrMappings } = this.state;
        const { ivrValues } = campaign;


        ivrValues.map(ivr => {
            if (!ivrMappings[ivr]) {
                ivrMappings[ivr] = '';
            }
        });


        let removedKeys = [];

        Object.keys(ivrMappings).forEach(element => {
            if (!ivrValues.includes(Number(element))) {
                removedKeys.push(element);
            }
        });

        removedKeys.map(key => {
            delete ivrMappings[key]
        });



        this.setState({
            ivrMappings
        })

    }

    _onAudioSuccess = (fileName, data) => {
        this.addAttachment('audioFile', fileName, data);
        return;

    }
    _onDataFileSuccess = (fileName, data) => {
        onTextChange(data.Key, 'leadsKey', this, campaignFields, this.state.campaign, this.state.formValidations, 'campaign', 'formValidations', null)
        // return this.convertDataFileToCSV(data.Key)


    }

    convertWave = (key) => {

        let url = `/convert-wave`

        this.setState({
            isLoading: true,
        });

        let thisRef = this;
        this.serverlessService.post(url, { key, campaignSessionId: this.state.campaignSessionId }, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState((prevState) => {
                    return {
                        campaign: {
                            ...prevState.campaign,
                            airtelAudioURL: 'uploaded'
                        },
                        isLoading: false
                    };
                });
            } else {

                this.setState((prevState) => {
                    return {
                        isLoading: false
                    };
                });
                this.show({ severity: 'error', summary: 'Some error occured', detail: 'Please contact admin', life: 3000 });
            }



        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })



    }


    convertDataFileToCSV = (key) => {
        let url = `/voice-bulk/leads-upload`
        this.setState({
            isLoading: true,
        });

        let thisRef = this;
        this.serverlessService.post(url, { key, campaignSessionId: this.state.campaignSessionId }, true).then((res) => {

            if (res && res.status && res.res.status) {
                //    console.log(res.res, 'leadsResponse')
                if (res.res.data) {
                    this.setState((prevState) => {
                        return {
                            campaign: {
                                ...prevState.campaign,
                                leadsDataCount: res.res.data.totalCount,
                            },
                            isLoading: false
                        };
                    });
                } else {
                    this.setState((prevState) => {
                        return {
                            isLoading: false
                        };
                    });
                    this.show({ severity: 'error', summary: 'Some error occured', detail: 'Some error occured while uploading file', life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })



    }


    addAttachment = (fieldName, fileName, data) => {
        onTextChange(data.Key, 'audioKey', this, campaignFields, this.state.campaign, this.state.formValidations, 'campaign', 'formValidations', null)
        //  this.convertWave(data.Key)

    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    formatUploadFile = (file) => {
        return {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
        }
    }


    _afterFilesUploaded = (fileKey, files) => {

        this.setState({
            [`file_${fileKey}`]: [...files].map(this.formatUploadFile),

        })
    }


    render() {
        const { campaign, formValidations, ivrMappings } = this.state;

        //   console.log(this.state, ivrMappings, 'state-appointment')
        return (<>
            <Dialog header={`${this.props.campaign ? 'Edit' : 'Create'} Campaign`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={false}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>

                <div>
                    <div className="p-grid sp-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Campaign Source<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <RadioButton className="radio-inline " value='backend' inputId="se"
                                name="templateType"
                                disabled
                                onChange={(e) => onTextChange(e.value, 'campaignSource', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations', () => {
                                    this.updateAudioFormFields(e.value == 'sendshortly')
                                })}
                                checked={campaign.campaignSource === 'backend'} />
                            <label className='sp-label-s1' htmlFor="se">Backend</label>
                            <RadioButton className="radio-inline  sp-ml10" value='sendshortly' inputId="si"
                                name="templateType"
                                disabled
                                onChange={(e) => onTextChange(e.value, 'campaignSource', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations', () => {
                                    this.updateAudioFormFields(e.value == 'sendshortly')

                                })}
                                checked={campaign.campaignSource === 'sendshortly'} />
                            <label className='sp-label-s1 ' htmlFor="si">Portal</label>

                            {formValidations && !formValidations.fields['campaignSource'].isValid && <p className="p-error">{formValidations.fields['campaignSource'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid sp-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Campaign Name<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={campaign.campaignName}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'campaignName', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations') }} />
                            {formValidations && !formValidations.fields['campaignName'].isValid && <p className="p-error">{formValidations.fields['campaignName'].errorMsg}</p>}
                        </div>
                    </div>

                    <div className="p-grid sp-mt20">

                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label'>Campaign Description<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            {/* <InputTextarea value={campaign.campaignDescription}
                                autoComplete="new-password"
                                rows={4}
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onTextChange(e.target.value, 'campaignDescription', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations') }} /> */}

                            <ClassicEditor
                                data={campaign.campaignDescription}
                                classNamee="email-box"
                                onChange={(value) => { onTextChange(value, 'campaignDescription', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations') }}
                            />
                            {formValidations && !formValidations.fields['campaignDescription'].isValid && <p className="p-error">{formValidations.fields['campaignDescription'].errorMsg}</p>}
                        </div>
                    </div>

                    {
                        campaign.campaignSource === 'backend' && <>
                            <div className="p-grid ">
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label' >Campaign Id<span className='sp-required'>*</span></p>

                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <InputText value={campaign.campaignId}
                                        autoComplete="new-password"
                                        className='sp-w200 p-inputtext-style1'
                                        onChange={(e) => { onTextChange(e.target.value, 'campaignId', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations') }} />
                                    <span className='text-label'> <b> Note:</b> Contact Administrator for Campaign Id</span>
                                    {formValidations && !formValidations.fields['campaignId'].isValid && <p className="p-error">{formValidations.fields['campaignId'].errorMsg}</p>}
                                </div>
                            </div>

                        </>
                    }

                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Audio Length<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputText value={campaign.voiceLength}
                                autoComplete="new-password"
                                className='sp-w200 p-inputtext-style1'
                                onChange={(e) => { onNumberChange(e.target.value, 'voiceLength', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations') }} />

                            {formValidations && !formValidations.fields['voiceLength'].isValid && <p className="p-error">{formValidations.fields['voiceLength'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Campaign Type<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <Dropdown value={campaign.campaignType}
                                options={[
                                    {
                                        label: 'Normal',
                                        value: 'normal'
                                    },
                                    {
                                        label: 'IVR',
                                        value: 'ivr'
                                    }
                                ]}
                                onChange={(e) => onTextChange(e.value, 'campaignType', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations', () => {
                                    this.updateFormFields(e.value == 'ivr')
                                })}
                                placeholder="Campaign Type" />
                            {formValidations && !formValidations.fields['campaignType'].isValid && <p className="p-error">{formValidations.fields['campaignType'].errorMsg}</p>}
                        </div>
                    </div>


                    {
                        campaign.campaignType == 'ivr' && <>
                            <div className="p-grid ">
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label' >IVR Values<span className='sp-required'>*</span></p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <MultiSelect value={campaign.ivrValues}
                                        options={IVR_VALUES.map(a => {
                                            return {
                                                label: a,
                                                value: a
                                            }
                                        })}
                                        onChange={(e) => onChipsChange(e.value, 'ivrValues', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations', null, this.onIVRValueChange)}
                                        placeholder="Campaign Type" />
                                    {(formValidations && !formValidations.fields['ivrValues'].isValid) && <p className="p-error">{formValidations.fields['ivrValues'].errorMsg}</p>}
                                </div>
                            </div>
                            <div className="p-grid ">
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label' >IVR Mappings<span className='sp-required'>*</span></p>
                                </div>
                                <div className="p-col-6 p-md-6 p-lg-6 sp-mt2 sp-ml8 sp-m10 p-inputtext-style1">

                                    {
                                        Object.keys(ivrMappings).map(key => {
                                            return <div className='sp-mt10 p-grid'>
                                                <div className="p-col-8 p-md-10 p-lg-10 sp-mt2">
                                                    <label className='sp-mr10'><b>{key}:</b></label>
                                                    <InputText value={ivrMappings[key]}
                                                        className='sp-w200 p-inputtext-style1'
                                                        // options={this.state.sheetColumns}
                                                        onChange={(e) => this.onChangeField(key, e.target.value)}
                                                        placeholder="Enter the value" />
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>

                            </div>


                        </>

                    }
                    {
                        campaign.campaignSource == 'sendshortly' && <div className="p-grid ">
                            <div className="p-col-4 p-md-2 p-lg-2">
                                <p className='text-label' >Retry Count<span className='sp-required'>*</span></p>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                <Dropdown value={campaign.retryCount}
                                    options={[
                                        {
                                            label: '0',
                                            value: "0"
                                        },
                                        {
                                            label: '1',
                                            value: "1"
                                        },
                                        {
                                            label: '2',
                                            value: "2"
                                        },
                                        {
                                            label: '3',
                                            value: "3"
                                        }
                                    ]}
                                    onChange={(e) => onNumberChange(e.value, 'retryCount', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations')}
                                    placeholder="Retry Count" />
                                {formValidations && !formValidations.fields['retryCount'].isValid && <p className="p-error">{formValidations.fields['retryCount'].errorMsg}</p>}
                            </div>
                        </div>
                    }


                    <div className="p-grid ">
                        <div className="p-col-4 p-md-2 p-lg-2">
                            <p className='text-label' >Live Report<span className='sp-required'>*</span></p>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                            <InputSwitch
                                onChange={(e) => onTextChange(e.value, 'isLiveReportNeeded', this, campaignFields, campaign, formValidations, 'campaign', 'formValidations')}
                                checked={campaign.isLiveReportNeeded} />
                            {formValidations && !formValidations.fields['isLiveReportNeeded'].isValid && <p className="p-error">{formValidations.fields['isLiveReportNeeded'].errorMsg}</p>}
                        </div>
                    </div>

                    {
                        campaign.campaignSource == 'sendshortly' && <>

                            <div className='p-grid'>
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Audio</p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <div className='custom-input2 file-attach'>
                                        <DirectFileUpload id={"audiofile"}
                                            multiple={false}
                                            onProgress={this._onProgress}
                                            onSucess={this._onAudioSuccess}
                                            maxFileSize={307200000}
                                            onError={this._onFilsUploadError}
                                            accept={'airtel-audio'}
                                            title={'Images'}
                                            afterFilesUploaded={(files) => this._afterFilesUploaded('audioKey', files)}
                                        >
                                            <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                                        </DirectFileUpload>
                                        {
                                            this.state.file_audioKey && <p>{this.state.file_audioKey[0].name}</p>
                                        }
                                        {/* {
                                            campaign.airtelAudioURL && <audio controls>
                                                <source src={campaign.airtelAudioURL} />
                                            </audio>
                                        } */}
                                        {formValidations && !formValidations.fields['audioKey'].isValid && <p className="p-error">{formValidations.fields['audioKey'].errorMsg}</p>}
                                    </div>
                                </div>
                            </div>

                            <div className='p-grid'>
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Leads Data File</p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <div className='custom-input2 file-attach'>
                                        <DirectFileUpload id={"airtelLeadFile"}
                                            multiple={false}
                                            onProgress={this._onProgress}
                                            onSucess={this._onDataFileSuccess}
                                            maxFileSize={307200000}
                                            onError={this._onFilsUploadError}
                                            accept={'airtel-csv'}
                                            title={'Images'}
                                            afterFilesUploaded={(files) => this._afterFilesUploaded('leadsKey', files)}
                                        >
                                            <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                                        </DirectFileUpload>
                                        {
                                            this.state.file_leadsKey && <p>{this.state.file_leadsKey[0].name}</p>
                                        }
                                        {formValidations && !formValidations.fields['leadsKey'].isValid && <p className="p-error">{formValidations.fields['leadsKey'].errorMsg}</p>}
                                        {/* {
                                            campaign.leadsDataCount && <p>File uploaded successfully.Total Leads: {campaign.leadsDataCount}</p>
                                        } */}
                                    </div>
                                    <a href='#' className='sp-mt10' onClick={() => {
                                        window.open(`${CLOUDFRONT_ENDPOINT}uploads/sample_data_sheet.xlsx`)

                                    }}>Download Sample Sheet</a>
                                </div>

                            </div>
                        </>
                    }

                    {/* <div dangerouslySetInnerHTML={{ __html: campaign.campaign }}></div> */}

                </div>

                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateCampaign)));
