import { Toast } from "primereact/toast";
import React, { Component } from "react";
import { connect } from 'react-redux';
import Header from './../Header';
import { withRouter } from 'react-router-dom';
import Authentication from '../session/index'
import LoadingComponent from "src/LoadingComponent";
import SingleReport from "./singleReport";
import ServerlessService from "src/Service/ServerlessService";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";


var timer2;
class AllReportInSinglePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignIds: [],
      reloadStarted: false,
      refreshCount: 1
      // '1234566e346428f72641234', '1234566e346428f72641234'
    }

    this.serverlessService = new ServerlessService();
  }


  getVoiceCampigns = () => {

    this.setState({
      isLoading: true,
      campaignIds: []
    });
    const url = `/voice-bulk/get-list-of-campaigns`;
    this.serverlessService.post(url, {}, true).then((res) => {


      if (res && res.status && res.res.status) {
        this.setState({
          isLoading: false,
          campaignIds: res.res.data,
        })

      } else {

        this.setState({
          isLoading: false
        });
        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
      }

    }).catch(e => {
      console.log(e);
      this.setState({
        isLoading: false
      });
      this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
    })




  }

  componentWillUnmount() {
    clearInterval(timer2)
  }

  componentDidMount() {

    this.getVoiceCampigns()

  }
  render() {
    return <Header>
      <div className='sp-main sp-card sp-p20 sp-mt20'>
        <Toolbar className="sp-toolbar"
          right={() => {
            return (<> {!this.state.reloadStarted ? <Button label="Start Reload" icon="pi pi-reload" onClick={() => {
              this.setState((prevState) => {
                return {
                  reloadStarted: true,
                  refreshCount: prevState.refreshCount + 1
                }
              });
              timer2 = setInterval(() => {
                this.setState((prevState) => {
                  return {
                    refreshCount: prevState.refreshCount + 1
                  }
                });
              }, 30000);
            }} /> : <Button label="Stop Reload" className='sp-ml10 p-button-danger' icon="pi pi-reload" onClick={() => {
              this.setState({
                reloadStarted: false
              });

              clearInterval(timer2)
            }} />

            }</>
            )
          }}
          left={() => {
            return (<>

            </>)
          }}
        ></Toolbar>
        <h4>All Campaign Report</h4>
        {this.state.campaignIds.map((campaignId) => <SingleReport refreshCount={this.state.refreshCount} key={campaignId} campaignId={campaignId} />)}




        {
          this.state.isLoading && <LoadingComponent />
        }
        <Toast ref={(el) => this.toast = el} position="bottom-right" />
      </div>

    </Header>

  }
}



const mapStateToProps = (state) => ({
  user: state.user,
  userType: state?.user?.userType,
  authenticated: state?.user?.authenticated,
  isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(AllReportInSinglePage)));
