import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print';

import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PieChart, Pie, Sector, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import LoadingComponent from 'src/LoadingComponent';
import APIService from 'src/Service/apiService';
import { MATRIMONY_CLOUDFRONT, LONG_RUNNING_PRESIGNED_URL } from 'src/Service/config';

import { Toolbar } from 'primereact/toolbar';


var timer;
const COLORS = ['#028020', '#D32F2F'];

const COLORS_FOR__DTMF = ['#028020', '#e6981c', '#0088FE', '#5c23cf', '#e01272', '#92d624', '#28edaf'];

const COLORS_FOR__PARTY = { "A1": '#028020', "A2": '#e6981c', "A3": '#0088FE', "A4": '#5c23cf', "Ainvalid": '#FFBA00' };


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.28;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);


    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {/* {`${(percent * 100).toFixed(0)}%`} */}
            {payload?.payload?.value}%
        </text>
    );
};

const renderCustomizedLabelForDTMF = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);


    return (
        <text x={x + 5} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {/* {`${(percent * 100).toFixed(0)}%`} */}
            {payload?.payload?.name != 'invalid' ? payload?.payload?.name : '*'} -  {payload?.payload?.value}%
        </text>
    );
};


export default class CampaignReports extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reloadStarted: false,
            dtmfValusMappingData: [],
            selectedCampaign: this.props.selectedCampaign,
            "answerUnanswerReport": [

            ],
            "dtmfReport": [

            ],
            "pulseStats": {

            },
            "pulseSlots": {

                "60-70": 0,
                "70-80": 0,
                "80-90": 0,
                "90-100": 0
            },
            apexDonut: {
                series: [44, 55, 41, 17, 15],
                options: {
                    chart: {
                        width: 380,
                        type: 'donut',
                    },
                    plotOptions: {
                        pie: {
                            startAngle: -90,
                            endAngle: 270
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        type: 'gradient',
                    },
                    legend: {
                        formatter: function (val, opts) {
                            return val + " - " + opts.w.globals.series[opts.seriesIndex]
                        }
                    },
                    title: {
                        text: 'Gradient Donut with custom Start-angle'
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },


            }

        }
        this.apiService = new APIService();
    }


    // removeUnnessaryRemove = (data) => {

    //     // let data = {
    //     //     "answerUnanswerReport": [
    //     //         {
    //     //             "name": "Answered",
    //     //             "count": 15586,
    //     //             "value": 51
    //     //         },
    //     //         {
    //     //             "name": "Un Answered",
    //     //             "count": 14707,
    //     //             "value": 49
    //     //         }
    //     //     ],
    //     //     "dtmfReport": [
    //     //         {
    //     //             "name": "0",
    //     //             "count": 9,
    //     //             "value": 0
    //     //         },
    //     //         {
    //     //             "name": "1",
    //     //             "count": 1182,
    //     //             "value": 39
    //     //         },
    //     //         {
    //     //             "name": "2",
    //     //             "count": 907,
    //     //             "value": 30
    //     //         },
    //     //         {
    //     //             "name": "3",
    //     //             "count": 540,
    //     //             "value": 18
    //     //         },
    //     //         {
    //     //             "name": "4",
    //     //             "count": 355,
    //     //             "value": 12
    //     //         },
    //     //         {
    //     //             "name": "5",
    //     //             "count": 6,
    //     //             "value": 0
    //     //         },
    //     //         {
    //     //             "name": "6",
    //     //             "count": 2,
    //     //             "value": 0
    //     //         },
    //     //         {
    //     //             "name": "7",
    //     //             "count": 4,
    //     //             "value": 0
    //     //         },
    //     //         {
    //     //             "name": "8",
    //     //             "count": 5,
    //     //             "value": 0
    //     //         },
    //     //         {
    //     //             "name": "9",
    //     //             "count": 1,
    //     //             "value": 0
    //     //         }
    //     //     ],
    //     //     "pulseStats": {
    //     //         "correct": 0,
    //     //         "incorrect": 0,
    //     //         "cdrIds": []
    //     //     },
    //     //     "pulseSlots": {
    //     //         "0-10": 0,
    //     //         "10-20": 0,
    //     //         "20-30": 0,
    //     //         "30-40": 0,
    //     //         "40-50": 0,
    //     //         "50-60": 0,
    //     //         "60-70": 0,
    //     //         "70-80": 0,
    //     //         "80-90": 0,
    //     //         "90-100": 0
    //     //     },
    //     //     "report": {
    //     //         "answered": 15586,
    //     //         "unAnswered": 14707,
    //     //         "total": 30293
    //     //     }

    //     // };
    //     const { ivrValues, campaignType, ivrMappings } = this.state.selectedCampaign;


    //     let dtmfValusMappingData = [];

    //     
    //     if (campaignType == 'ivr') {
    //         const _ivrValues = ivrValues.map(a => a.toString());

    //         const otherDTMF = {
    //             "name": "invalid",
    //             "count": 0,
    //             "value": 0
    //         }

    //         const otherValueMapping = {
    //             dtmfValue: 'invalid',
    //             dtmfName: 'Invalid input',
    //             count: 0,
    //             percentage: 0
    //         };

    //         data.dtmfReport = data.dtmfReport.filter(a => {
    //             let d = _ivrValues.includes(a.name);
    //             if (!d) {
    //                 otherDTMF.count += a.count;
    //                 otherDTMF.value += a.value;


    //                 otherValueMapping.count += a.count;
    //                 otherDTMF.percentage += a.value;

    //             } else {
    //                 dtmfValusMappingData.push({
    //                     dtmfValue: a.name,
    //                     dtmfName: ivrMappings[a.name],
    //                     count: a.count,
    //                     percentage: a.value
    //                 });
    //             }
    //             return d;
    //         });
    //         dtmfValusMappingData.push(otherValueMapping)
    //         dtmfValusMappingData = dtmfValusMappingData.sort((a, b) => b.count - a.count).map((a, index) => {
    //             a.ranking = index + 1
    //             return a;
    //         });

    //         // dtmfValusMappingData = dtmfValusMappingData.sort((a, b) => Number(a.dtmfValue) - Number(b.dtmfValue))

    //         data.dtmfReport = data.dtmfReport.concat(otherDTMF);
    //         data.dtmfValusMappingData = dtmfValusMappingData;

    //     }

    //     return data;

    // }

    getReportByCampaignId = (campaignId) => {

        const { ivrValues, campaignType, ivrMappings } = this.props.selectedCampaign;


        let colors = []
        this.setState({
            isLoading: true,

        });
        const url = `${LONG_RUNNING_PRESIGNED_URL}/report/getCampaignReport-v1/${campaignId}`;
        this.apiService.post(url, { ivrValues, campaignType, campaignId, ivrMappings }).then((res) => {
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.report && res.res.data.report.total) {
                    this.setState({
                        isLoading: false,
                        noReports: false,
                        ...res.res.data,
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        noReports: true
                    })
                    this.toast.show({ severity: 'error', summary: 'Not started', detail: 'Campaign was not started', life: 3000 });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }


    componentWillUnmount() {
        clearInterval(timer)

    }

    componentDidMount() {
        const { campaignId } = this.props.selectedCampaign

        this.getReportByCampaignId(campaignId)

    }

    renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };


    getWinnerClassName = (ranking) => {
        switch (ranking) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return `c${ranking}-winner`
            default:
                return ''
        }


    }


    render() {
        console.log(this.state, 'selectedCampaign')
        const {
            selectedCampaign
        } = this.state;
        const {
            pieChart,

            barChart,
            dtmfReport,
            answerUnanswerReport,
            report
        } = this.state;
        return (
            <div className=''>

                <Toolbar className="sp-toolbar"
                    right={() => {
                        return (<> {!this.state.reloadStarted ? <Button label="Start Reload" icon="pi pi-reload" onClick={() => {
                            this.getReportByCampaignId(selectedCampaign.campaignId)
                            this.setState({
                                reloadStarted: true
                            })
                            timer = setInterval(() => {
                                this.getReportByCampaignId(selectedCampaign.campaignId)
                            }, 30000);
                        }} /> : <Button label="Stop Reload" className='sp-ml10 p-button-danger' icon="pi pi-reload" onClick={() => {
                            this.setState({
                                reloadStarted: false
                            });

                            clearInterval(timer)
                        }} />

                        }</>
                        )
                    }}
                    left={() => {
                        return (<>
                            <Button label="Back" icon="pi pi-arrow-left" onClick={this.props.onBack} />
                        </>)
                    }}
                ></Toolbar>
                {
                    // !this.state.isLoading &&
                    <>

                        <div ref={el => (this.componentRef = el)} className='report-wrapper' >
                            <div className=' p-card campagin-details-w' >
                                <Toolbar className="sp-toolbar "
                                    right={() => {
                                        return (
                                            <ReactToPrint
                                                trigger={() => {
                                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                    // to the root node of the returned component as it will be overwritten.
                                                    return <Button label="Print" icon="pi hide-on-print" />
                                                }}
                                                content={() => this.componentRef}
                                            />



                                        )
                                    }}
                                    left={() => {
                                        return (<>
                                            <h2>
                                                Campaign Details
                                            </h2>
                                        </>)
                                    }}
                                ></Toolbar>

                                <div className="p-grid " >
                                    <div className="p-col-3 p-md-3 p-lg-3" >
                                        <p className='sp-label-s1 sp-mb0'>Name</p>
                                        <h3 className='sp-no-pm'>{selectedCampaign.campaignName}</h3>
                                    </div>
                                    <div className="p-col-3 p-md-3 p-lg-3" >
                                        <p className='sp-label-s1 sp-mb0'>Id</p>
                                        <h3 className='sp-no-pm'>{selectedCampaign.campaignId}</h3>
                                    </div>
                                    <div className="p-col-3 p-md-3 p-lg-3" >
                                        <p className='sp-label-s1 sp-mb0'>Type</p>
                                        <h3 className='sp-no-pm'>{selectedCampaign.campaignType.toUpperCase()}</h3>
                                    </div>
                                    <div className="p-col-3 p-md-3 p-lg-3" >
                                        <p className='sp-label-s1 sp-mb0'>Retry Count</p>
                                        <h3 className='sp-no-pm'>{selectedCampaign.retryCount}</h3>
                                    </div>
                                </div>
                                <div className="p-grid sp-mt20" >
                                    <div className="p-col-3 p-md-3 p-lg-3" >
                                        <p className='sp-label-s1 sp-mb0'>Audio length (Sec)</p>
                                        <h3 className='sp-no-pm'>{selectedCampaign.audioLength}</h3>
                                    </div>
                                    <div className="p-col-3 p-md-3 p-lg-3" >
                                        <p className='sp-label-s1 sp-mb0'>IVRs</p>
                                        <h3 className='sp-no-pm'>{selectedCampaign.ivrValues.join(', ')}</h3>
                                    </div>
                                    <div className="p-col-3 p-md-3 p-lg-3" >
                                        <p className='sp-label-s1 sp-mb0'>Live Report</p>
                                        <InputSwitch
                                            checked={selectedCampaign.isLiveReportNeeded} />
                                    </div>
                                    <div className="p-col-3 p-md-3 p-lg-3 hide-on-print" >
                                        <p className='sp-label-s1 sp-mb0'>Download Report</p>
                                        <Button label='Download' onClick={() => {
                                            //  handleDownloadFromUrl(`${MATRIMONY_CLOUDFRONT}${this.state.s3DataSheetKey}`, `${this.state.selectedCampaign.campaignName}.csv`)
                                            window.open(`${MATRIMONY_CLOUDFRONT}custemer-cdr-logs/${selectedCampaign.campaignId}.xlsx`)
                                        }}></Button>
                                    </div>
                                </div>
                                <div className="p-grid sp-mt20" >
                                    <div className="p-col-12" >
                                        <p className='sp-label-s1 sp-mb0'>Description</p>
                                        <div dangerouslySetInnerHTML={{ __html: selectedCampaign.campaignDescription }}></div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.noReports ? <div className=' p-card campagin-details-answered' >
                                    <h4 className='no-campaign sp-center sp-m20'>Campaign not started. Try again after sometimes</h4>
                                </div> :

                                    <>
                                        <div className=' p-card campagin-details-answered' >
                                            <h2 className='sp-center sp-mt20'>Answered/Unanswered Report</h2>
                                            <div className="p-grid">
                                                <div className="p-col-4 p-md-4 p-lg-4" style={{ height: '230px' }} >
                                                    <PieChart width={800} height={400}>
                                                        <Pie
                                                            data={answerUnanswerReport}
                                                            cx="50%"
                                                            cy="50%"
                                                            innerRadius={10}
                                                            labelLine={false}
                                                            label={renderCustomizedLabel}
                                                            outerRadius={90}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {answerUnanswerReport.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                    </PieChart>
                                                </div>
                                                <div className="p-col-8 p-md-8 p-lg-8" >
                                                    <div className="p-grid" style={{ marginTop: '90px' }}>
                                                        <div className="p-col-4 p-md-4 p-lg-4" >
                                                            <Button label={report?.total || '0'} className="p-button-secondary" />
                                                            <p className='sp-label-s1 sp-mb0'>Total</p>
                                                        </div>
                                                        <div className="p-col-4 p-md-4 p-lg-4" >
                                                            <Button label={report?.answered || '0'} className="p-button-success" />
                                                            <p className='sp-label-s1 sp-mb0'>Answered</p>
                                                        </div>

                                                        <div className="p-col-4 p-md-4 p-lg-4" >
                                                            <Button label={report?.unAnswered || '0'} className="p-button-danger" />
                                                            <p className='sp-label-s1 sp-mb0'>Unanswered</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.dtmfReport && this.state.dtmfReport.length ? <>
                                                <div className=' p-card  campagin-dtmf-report' >
                                                    <h2 className='sp-center sp-mt20'>DTMF Report</h2>
                                                    <div className="p-grid">
                                                        <div className="p-col-6 p-md-6 p-lg-6"  >

                                                            <DataTable
                                                                className='dtmf-report-table'
                                                                value={this.state.dtmfValusMappingData}
                                                                dataKey="_id"
                                                                rows={100}
                                                            >
                                                                <Column field="dtmfValue" header="Response" />
                                                                <Column style={{ width: '240px' }} field="dtmfName" sty header="Voting" body={(rowData) => {
                                                                    return <div className={`c${rowData.ranking}-winner`} style={{ color: `${COLORS_FOR__PARTY[`A${rowData?.dtmfValue}`]}` }}>
                                                                        {rowData?.dtmfName}
                                                                    </div>
                                                                }} />
                                                                <Column field="count" header="Votes Count" />
                                                                <Column field="percentage" header="Percentage" />
                                                            </DataTable>

                                                        </div>
                                                        <div className="p-col-6 p-md-6 p-lg-6" style={{ height: '530px' }} >
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <PieChart width={500} height={500}>
                                                                    <Pie
                                                                        data={dtmfReport}
                                                                        cx="50%"
                                                                        cy="50%"
                                                                        labelLine={false}
                                                                        label={renderCustomizedLabelForDTMF}
                                                                        outerRadius={160}
                                                                        fill="#8884d8"
                                                                        dataKey="value"
                                                                    >
                                                                        {dtmfReport.map((entry, index) => (
                                                                            <Cell key={`cell-${index}`} fill={COLORS_FOR__PARTY[`A${entry?.name}`]} />
                                                                        ))}
                                                                    </Pie>
                                                                </PieChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                    </div>
                                                    <p class="footer-p printable" >Powered by SendShortly</p>
                                                </div>
                                            </> : <>

                                            </>
                                        }
                                    </>
                            }
                        </div>
                    </>
                }
                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div >
        )
    }
}